/* eslint-disable prefer-destructuring */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';
import { Credits } from '../components/credits';
import { cmsContentExists } from '../helpers/cms-content-exists';

const Program = ({ data }) => {
  const program = data.datoCmsProgram;

  return (
    <Layout
      bgcolor="#fff"
      wrapperClass="program-single"
      accentColor={program.accentColor}
    >
      <HelmetDatoCms seo={program.seoMetaTags} />
      <div className="container row">
        <div className="col-sm-2 col-xs-6 col">
          <Link to="/education" className="sidebar-text">
            Education
          </Link>
        </div>
        <div className="col-sm-8 col-xs-6 col">
          <h1
            className="gothic--large large-title"
            dangerouslySetInnerHTML={{
              __html: program.title,
            }}
          />
        </div>
      </div>
      <div className="program-content container row reverse single-article-content">
        <div className="program-images col-sm-4 offset-sm-1 col-xs-6 col">
          {program.images.map(({ fluid, alt }) => (
            <div key={Math.random()} className="program-feature-image">
              <Img fluid={fluid} alt={alt} />
            </div>
          ))}
          <div className="program-divider col-xs-5 col spacing-0">
            <DividerBlock accentColor={program.accentColor} />
          </div>
        </div>
        <div className="col-sm-7 offset-sm-2 col-xs-6 col">
          <div
            className="program-description content"
            dangerouslySetInnerHTML={{
              __html: program.description,
            }}
          />
        </div>
      </div>
      {program.workshops.length > 0 && (
        <section aria-labelledby="program-w" className="program-workshops container row">
          <div className="col-sm-2 col-xs-6 col">
            <h2 id="program-w" className="sidebar-text">Workshops</h2>
          </div>
          <ul
            aria-label="Program workshops"
            className="tiles tiles-3 col-sm-12 col-xs-6 col spacing-0 row"
          >
            {program.workshops.map(({
              image,
              id,
              description,
              title,
              moreInfoText,
              flyer,
              flyerLinkText,
            }) => (
              <li className="tile workshop col" key={id}>
                <Img className="tile-image" fluid={image.fluid} key={Math.random()} />
                <div className="tile-text-wrap">
                  <h3
                    className="sans--medium"
                    dangerouslySetInnerHTML={{
                      __html: title,
                    }}
                  />
                  <div
                    className="content description"
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                  {(cmsContentExists(flyer)) && (
                    <div className="more-info content">
                      {cmsContentExists(moreInfoText) && (
                        <span
                          className="more-info-text"
                          dangerouslySetInnerHTML={{ __html: moreInfoText }}
                        />
                      )}
                      {cmsContentExists(flyerLinkText) && (
                        <a className="more-link" href={flyer.url}>{flyerLinkText}</a>
                      )}
                    </div>
                  )}

                </div>
              </li>
            ))}
          </ul>
        </section>
      )}
      <Credits
        creditText={program.credit}
        creditLogos={program.creditLogos}
        additionalInfo={program.additionalInfo}
      />
      <div className="program-footer container row">
        <div className="col-sm-7 offset-sm-2 col-xs-6 col">
          <Link to="/education" className="back-link">
            Back to Education
          </Link>
        </div>
        <div className="col-sm-5 col-xs-4 offset-sm-3 offset-xs-1 col">
          <DividerBlock accentColor={program.accentColor} />
        </div>
      </div>
    </Layout>
  );
};

Program.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Program;

export const query = graphql`
  query ProgramQuery($slug: String!) {
    datoCmsProgram(slug: { eq: $slug }) {
      title
      description
    accentColor {
        hex
    }
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    images {
      alt
      fluid(maxHeight: 270) {
      ...GatsbyDatoCmsSizes_noBase64
      }
    }
    creditLogos {
      alt
      fixed(height: 50) {
      ...GatsbyDatoCmsFixed
      }
    }
    credit
      additionalInfo
      workshops {
        image {
          alt
          fluid(maxHeight: 240) {
            ...GatsbyDatoCmsSizes_noBase64
          }
        }
        id
        description
        title
        moreInfoText
        flyer {
          alt
          url
        }
        flyerLinkText
      }
    }
  }
`;
